<template>
  <div>
    <b-card id="pageOne" class="p-0 mb-n5 text-center" width="1080">
      <b-img class="bgPic" :src="bgPic" width="700" />
      <div class="d-flex flex-column align-items-center justify-content-center">
        <b-card
          class="shadow d-flex justify-content-center align-items-center flex-column"
          width="192"
          height="192"
        >
          <b-img :src="hecter" width="192" />
        </b-card>
        <div class="text-center">
          <!-- <h3 class="companyName">{{ property.company }}</h3> -->
          <h3 class="companyAdd">
            <img src="@/assets/images/icons/map.png" loading="lazy" />
            {{ property.propertyAddress.address }}
          </h3>
          <h6 class="companyLocations">
            مناطق الخدمه
          </h6>
          <!-- <h3 class="companyAdd mt-2">{{ property.propertyAddress.city }}</h3> -->
        </div>

        <div class="d-flex justify-content-center companyAdd">
          <div
            class="side-card d-flex justify-content-center align-items-center"
          >
            <div>
              <h5 class="forSale">للبيع</h5>
              <h5 v-if="false" class="issuedNum">
                {{ "fg" }}<span class=" mr-1">عقار</span>
              </h5>
              <h5 v-else class="issuedNum">لا يوجد</h5>
            </div>
          </div>
          <div
            class="side-card d-flex justify-content-center align-items-center"
          >
            <div>
              <h5 class="forSale">للايجار</h5>
              <h5 v-if="false" class="issuedNum">
                {{ "dsfs" }}<span class="mr-1">عقار</span>
              </h5>
              <h5 v-else class="issuedNum">لا يوجد</h5>
            </div>
          </div>
        </div>

        <b-img :src="hecter" class="companyAdd m-64" width="142" />
      </div>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "page1",
  props: ["property"],
  data() {
    return {
      bgPic: require("@/assets/images/pdf/1.png"),
      hecter: require("@/assets/images/logo.svg"),
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/pdf/pageOne";
</style>
