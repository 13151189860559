<template>
    <div id="pageThree" class="text-center">
        <h2 class="mt-5">
            مرافق العقار
        </h2>
        <div class="mb-5 mx-5">
            <div class=" d-flex justify-content-center align-center">
                <div class="radiusCard ml-4 d-flex flex-column align-content-center justify-content-center flex-wrap">
                    <img src="@/assets/images/pdf/5.svg" alt="PDF Image" loading="lazy">
                    <h5 class="d-inline">عدد الغرف</h5>
                    <h4 class="d-inline">{{property.numberOfRooms}}</h4>
                </div>
                <div class="radiusCard  d-flex flex-column align-content-center justify-content-center flex-wrap">
                    <img src="@/assets/images/pdf/6.svg" alt="PDF Image" loading="lazy">
                    <h5>عدد دورات المياة</h5>
                    <h4>{{property.numberOfBathRoom}}</h4>
                </div>
            </div>
            <div class=" d-flex justify-content-between align-center">
                <div class="radiusCard  d-flex flex-column align-content-center justify-content-center flex-wrap">
                    <img src="@/assets/images/pdf/2.svg" alt="PDF Image" loading="lazy">
                    <h5 class="d-inline">عدد المطابخ</h5>
                    <h4>{{property.numberOfKitchenUnit}}</h4>
                </div>
                <div class="radiusCard radiusCardGreen mx-4 d-flex flex-column align-content-center justify-content-center flex-wrap">
                    <img src="@/assets/images/pdf/Vector.svg" alt="PDF Image" loading="lazy">
                    <h5>تحتوي علي </h5>
                    <h4 v-if="property.features && property.features.length > 0" >{{property.features[0].nameAr}}</h4>
                </div>
                <div class="radiusCard  d-flex flex-column align-content-center justify-content-center flex-wrap">
                    <img src="@/assets/images/pdf/3.svg" alt="PDF Image" loading="lazy">
                    <h5>تحتوي علي </h5>
                    <h4 v-if="property.features && property.features.length > 0">{{property.features[1].nameAr}}</h4>
                </div>
            </div>
            <div class=" d-flex justify-content-center align-center">
                <div class="radiusCard ml-4 d-flex flex-column align-content-center justify-content-center flex-wrap">
                    <img src="@/assets/images/pdf/4.svg" alt="PDF Image" loading="lazy">
                    <h5 class="d-inline">مساحة العقار</h5>
                    <h4 class="d-inline">{{property.space}}</h4>
                </div>
                <div class="radiusCard  d-flex flex-column align-content-center justify-content-center flex-wrap">
                    <img src="@/assets/images/pdf/1.svg" alt="PDF Image" loading="lazy">
                    <h5>عدد الصالات</h5>
                    <h4 class="d-inline">{{property.numberOfLivingRoom}}</h4>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "page3",
        props:['property']
    }
</script>

<style lang="scss" scoped>
    @import "@/styles/pdf/pageThree";
</style>
