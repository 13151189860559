<template>
    <div class="single-block" id="related-properties" v-if="hasProperties">
        <!-- Similar Listings Container -->
        <div class="related-propes-header">
            <h3 class="desc-headline no-border margin-bottom-35 margin-top-60">عقارات مشابهة</h3>
            <p>ربما تعجبك هذه العقارات</p>
            <div id="related-slider-nav"></div>
        </div>

        <div id="listings-list" class="listings-container grid-layout mt-4">
            <!-- Listing Item -->
            <slick ref="listingSlider" :options="relatedSlickOptions" class="related-sider">
                <listing-item
                    v-for="property in relatedProperties"
                    :key="property._id"
                    :property="property"
                    usedIn="relatedProperties"
                    :isInfavoritesView="false"
                >
                </listing-item>
            </slick>
        </div>
    </div>
    
</template>

<script>
import Slick from 'vue-slick';
import { realEstateService } from '../../services';
import { mapState  } from 'vuex';
import ListingItem from '@/components/property/ListingItem.vue';
import config from '@/config';

//Style
import '@/styles/property/related-properties.scss';

export default {
    components: {
        Slick,
        ListingItem,
    },
    data() {
        return {
            relatedProperties: [],
            relatedSlickOptions: {
                rows: 0,
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: false,
                rtl: true,
                lazyLoad: 'ondemand',
                appendArrows: '#related-slider-nav',
                responsive: [
                    {
                        breakpoint: 1600,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                        },
                    },
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        },
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        },
                    },
                ],
            },
            hasProperties: false,
        };
    },
    created(){
        this.getRelatedProperties();
    },
    computed: {
        ...mapState('accountState', ['status', 'user', 'favorites', 'favoritesIds']),
        isLoggedIn() {
            return this.status.loggedIn;
        },
        payTypeOptions(){
            return config.payTypeOptions;
        },
    },
    methods: {
        // Favorites Methods ----------------------------
        isInFavorite(id){
            if(this.favoritesIds && this.favoritesIds.length){
                return this.favoritesIds.includes(id);
            }
        },

        getRelatedProperties(){
            realEstateService.getRelatedProperties(this.$route.params.id).then(
                response => {
                    this.relatedProperties = response.data.suggestions;
                    if(response.data.suggestions.length === 0){
                        return this.hasProperties = false;

                    }
                    this.hasProperties = true;

                },
                error => {
                    console.log(error);
                }
            ).catch(error=>{
                console.log(error);
            });
        }
    },
};
</script>
