<template>
  <div>
    <div id="element-to-print">
      <page1 :property="property" />
      <div class="html2pdf__page-break"></div>
      <page2 :property="property" />
      <div class="html2pdf__page-break"></div>
      <page3 :property="property" />
      <div class="html2pdf__page-break"></div>
      <page4 :property="property.images" />
    </div>
  </div>
</template>

<script>
import html2pdf from "html2pdf.js";
import Page1 from "../pdf/page1";
import Page2 from "../pdf/page2";
import Page3 from "../pdf/page3";
import Page4 from "../pdf/page4";

export default {
  props: ["property"],
  components: { Page4, Page3, Page1, Page2 },
  data() {
    return {};
  },
  computed: {},
  methods: {
    async downloadPdf() {
      const element = document.getElementById("element-to-print");
      const opt = {
        filename: `${this.property.propertyAddress.address} عقار هكتار في .pdf`,
        image: { type: "JPEG", quality: 0.8 },
        html2canvas: {
          scale: 2,
          useCORS: true,
        },
        jsPDF: {
          margin: 0,
          unit: "in",
          format: "a4",
          orientation: "portrait",
          putOnlyUsedFonts: true,
          precision: 20,
        },
      };
      await html2pdf()
        .from(element)
        .set(opt)
        .save();
    },
  },
};
</script>
