<template>
  <div id="pageFour" class="text-center">
    <h2 class="mt-5">صور العقار</h2>
    <div class="mt-5 mx-5" v-if="property">
      <img
        v-for="image in property"
        :key="image"
        :src="image"
        alt="Property Icon"
        width="914"
        height="595"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'page4',
  props: ['property']
}
</script>

<style lang="scss" scoped>
@font-face {
  font-family: jannaBold;
  src: url('/fonts/janna/JannaLTBold.ttf');
}

#pageFour {
  h2 {
    font-family: jannaBold;
    font-size: 40px;
    color: #3d3d3d;
  }
}
</style>
