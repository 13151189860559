<template>
    <div id="pageTwo" class="d-flex justify-content-center align-items-center flex-column">
        <div class="text-center mt-auto">
            <h2>{{`${property.type.nameAr} ${property.status.nameAr}`}}</h2>
            <h6 class="my-5 mx-5">{{`${property.type.nameAr} ${property.status.nameAr} في ${property.propertyAddress.address}   ,مساحه  ${property.space}   ,عمر  العقار ${property.age}    ,عنوان العقار ${property.propertyAddress.address}`}}</h6>
            <h2> {{property.price}} <span>ريال</span></h2>
        </div>
        <b-card class="mt-auto mb-5">
            <b-img  :src="hecter"/>
        </b-card>
    </div>
</template>

<script>
    export default {
        name: "page2",
        props: ['property'],
        data() {
            return {
                hecter: require('@/assets/images/logo.svg')
            }
        },
    }
</script>


<style lang="scss" scoped>
    @import "@/styles/pdf/pageTwo";
</style>
