<template>
  <div>
    <div class="loading-wrap">
      <div class="inner-wrap" :class="{ isActive: loading }">
        <circle2></circle2>
      </div>
    </div>
    <!-- Header -->
    <div
      class="fs-container"
      :class="{
        'full-map': fullMap,
        'is-map-view': isMapView,
        'is-properties-view': !isMapView
      }"
    >
      <div class="fs-inner-container map-column">
        <div class="fullwidth-loading-wrap" v-if="spinnerLoading">
          <b-spinner label="Spinning" variant="primary"></b-spinner>
        </div>
        <!-- Map -->
        <div id="map-container">
          <div class="backbtns-wrap">
            <b-button @click="backBtn" id="back-btn" v-if="showBackLink">
              <span class="hi-arrow-right icon ml-2"></span>
              <span>عودة</span>
            </b-button>

            <b-button
              v-if="showMyPropertiesBtn"
              @click="fullMap = false"
              id="back-btn"
            >
              <span class="hi-apartment icon ml-2"></span>
              <span>عقاراتي</span>
            </b-button>

            <b-button
              v-if="showMyFavoritesBtn"
              @click="fullMap = false"
              id="back-btn"
            >
              <span class="hi-bookmark2-o icon ml-2"></span>
              <span>مفضلتي</span>
            </b-button>
          </div>
          <div id="map">
            <gmap-map
              ref="mapRef"
              :center="mapCenter"
              :zoom="mapZoom"
              :options="options"
              style="width: 100%; height: 100%"
              @tilesloaded="onTilesLoaded"
              @zoom_changed="onZoomChanged"
              @dragend="onDraged"
            >
              <gmap-info-window
                :options="infoWindowOptions"
                :opened="infoWinOpen"
                :position="infoWindow.position"
                @closeclick="closeInfoWinOpen"
              >
                <router-link
                  :to="`/property/${infoWindow.id}`"
                  class="map-window-info"
                >
                  <div class="infoWindow-img-wrap">
                    <div
                      class="infoWindow-img"
                      v-if="infoWindow.img"
                      :style="{ backgroundImage: `url(${infoWindow.img})` }"
                    ></div>
                    <h5>
                      <span>{{ infoWindow.type }}</span>
                      <span v-if="infoWindow.status">
                        {{ infoWindow.status }}</span
                      >
                    </h5>
                  </div>

                  <div class="window-info-inner">
                    <h3 v-if="infoWindow.price">
                      {{ numberWithCommas(infoWindow.price) }}
                      <span class="currency">ريال</span>
                    </h3>
                    <h3 v-else>
                      <span class="currency not-limited-price">علي السوم </span>
                    </h3>
                    <div class="listing-address" v-if="infoWindow.address">
                      <i class="hi-map-pin"></i>
                      <span>{{ infoWindow.address }}</span>
                    </div>
                    <div class="windown-ifno-features">
                      <span v-if="infoWindow.space" class="featured-item">
                        <span class="icon hi-area"></span>
                        <span>{{ infoWindow.space }}</span>
                      </span>

                      <span
                        v-if="infoWindow.numberOfRooms"
                        class="featured-item"
                      >
                        <span class="icon hi-bed"></span>
                        <span>{{ infoWindow.numberOfRooms }}</span>
                      </span>

                      <span
                        v-if="infoWindow.numberOfBathRoom"
                        class="featured-item"
                      >
                        <span class="icon hi-bathroom"></span>
                        <span>{{ infoWindow.numberOfBathRoom }}</span>
                      </span>
                    </div>
                  </div>
                </router-link>
              </gmap-info-window>

              <gmap-custom-marker
                v-for="(property, index) in propertiesFound"
                :key="`custom-marker-${index}`"
                :ref="`marker-${property._id}`"
                :id="`marker-${property._id}`"
                class="map-marker"
                :class="{
                  'is-active': propId == property._id && infoWinOpen == true,
                  'is-current': property._id == currentPropertyId
                }"
                :marker="{
                  lat: property.location.coordinates[1],
                  lng: property.location.coordinates[0]
                }"
              >
                <router-link
                  @mouseover.native="toggleInfoWindow(property, property._id)"
                  @mouseout.native="closeInfoWinOpen"
                  :to="{
                    name: 'property',
                    params: { id: property._id, images360: 0 }
                  }"
                  ref="noopener"
                >
                  <span
                    v-if="currentZoom > 12"
                    class="map-marker-price"
                    :class="{ 'is-visited': isInVisitedProps(property._id) }"
                  >
                    <span v-if="property.price">
                      <span class="price">{{
                        numberFormatter(property.price, 0)
                      }}</span>
                      <span class="currency"> ريال</span>
                    </span>
                    <span v-else>
                      <span class="currency">السعر غير محدد</span>
                    </span>
                  </span>
                  <span
                    v-else
                    class="dot-map-marker"
                    :class="{ 'is-visited': isInVisitedProps(property._id) }"
                  >
                  </span>
                </router-link>
              </gmap-custom-marker>

              <!-- Current Place Marker -->
              <gmap-custom-marker
                ref="current-property-marker"
                id="current-property-marker"
                class="map-marker is-current"
                :marker="mapCenter"
              >
                <span class="dot-map-marker"> </span>
              </gmap-custom-marker>
            </gmap-map>
          </div>
          <!-- Fullwidth toggle btn -->
          <button
            class="custom-map-button"
            id="fullwidth-btn"
            variant="light"
            :class="{ 'is-active': fullMap }"
            @click="fullMap = !fullMap"
          >
            <span class="hi-expand"></span>
          </button>
          <!-- Current Locatino btn -->
          <button class="custom-map-button" id="gps-btn" @click="geolocate()">
            <span class="hi-gps-fixed"></span>
          </button>
        </div>
      </div>
      <div
        class="fs-inner-container properties-column"
        :class="{ 'full-width-mages': fullWidthImages }"
      >
        <div
          class="fs-content"
          id="property-info-area"
          :class="{
            'images-360-is-active': images360IsActive,
            'has-images-366': hasImages360
          }"
        >
          <!-- Loading Placeholder -->
          <div v-if="loading" class="preview-area-wrap loadding-area">
            <div class="preview-area">
              <div id="previewSlider">
                <content-placeholders>
                  <content-placeholders-img />
                </content-placeholders>
              </div>
            </div>
          </div>

          <div v-else class="preview-area-wrap">
            <div class="preview-area">
              <div
                id="previewSlider"
                :class="{
                  'default-img': isPropertyHasNoImages && !isLand,
                  'default-land-img': isPropertyHasNoImages && isLand
                }"
              >
                <b-spinner
                  v-if="images && images.length"
                  label="Spinning"
                  variant="primary"
                ></b-spinner>

                <div v-if="isOwner" class="listing-badges">
                  <span v-if="property.active" class="property-status is-active"
                    >نشط</span
                  >

                  <span v-else class="property-status is-inactive">منتهي</span>
                </div>

                <div class="property-meta top-meta">
                  <span
                    v-if="!isOwner"
                    class="meta-item favorite-item mr-0"
                    v-b-tooltip.hover.right
                    title="اضف للمفضلة"
                  >
                    <a
                      :class="{
                        'in-favorite': isLoggedIn && isInFavorite(property._id)
                      }"
                      class="favorite-btn"
                      @click.prevent="toggleFavorite(property)"
                    >
                      <span class="hi-bookmark2-o icon"></span>
                    </a>
                  </span>

                  <span class="meta-item likes-icon">
                    <vue-star
                      ref="likeProperty"
                      :class="{ 'is-active': isLiked }"
                      color="#fff"
                    >
                      <a
                        slot="icon"
                        class="hi-thumbs-o-up"
                        @click="toggleLike(property._id)"
                      ></a>
                    </vue-star>
                    <span class="likes-count" v-if="numberOfLikes">{{
                      numberOfLikes
                    }}</span>
                  </span>
                  <span
                    class="meta-item share-item"
                    v-b-modal.share-property-modal
                    v-b-tooltip.hover.right
                    title="شارك العقار"
                  >
                    <span class="hi-share icon"></span>
                  </span>

                  <span
                    class="meta-item edit-item"
                    v-if="isOwner"
                    v-b-tooltip.hover.right
                    title="تعديل العقار"
                  >
                    <router-link
                      class="btn"
                      :to="`/add-property/${property._id}`"
                    >
                      <i class="hi-pencil"></i>
                    </router-link>
                  </span>
                </div>

                <share-property-modal
                  :shareData="property"
                ></share-property-modal>

                <div class="property-meta">
                  <span
                    v-if="images.length"
                    class="meta-item meta-btn images-count"
                    v-b-modal.fullwidth-images-modal
                  >
                    <i class="hi-image meta-item--icon"></i>
                    <span class="meta-item--text">{{ images.length }}</span>
                  </span>

                  <!-- ******PDF******-->

                  <span
                    v-if="
                      property.type.nameAr === 'مكتب' &&
                      property.status.nameAr === 'للبيع'
                    "
                    class="meta-item meta-btn download-pdf"
                    @click="pdfDownload"
                  >
                    <i class="hi-file-pdf-o meta-item--icon"></i>
                    <span class="meta-item--text">نسخة PDF</span>
                  </span>

                  <span v-if="images" class="meta-item date">
                    <i class="hi-time meta-item--icon"></i>
                    <span class="meta-item--text">{{
                      moment(property.createdAt).fromNow()
                    }}</span>
                  </span>

                  <span v-if="property.shows" class="meta-item views-count">
                    <i class="hi-eye meta-item--icon"></i>
                    <span class="meta-item--text">{{ property.shows }}</span>
                  </span>
                </div>

                <div id="images-360-wrap" v-if="images360IsActive">
                  <ul class="images-360-thumbs">
                    <li
                      v-for="(image, i) in images360"
                      :key="i"
                      @click="currentImages360 = i"
                      :class="{ 'is-active': currentImages360 == i }"
                      :style="{ backgroundImage: `url(${image.thumb})` }"
                    >
                      <span class="title">{{ image.title }}</span>
                    </li>
                  </ul>

                  <Vr360
                    :key="currentImages360"
                    id="images-360"
                    :imgSrc="images360[currentImages360].url"
                  />

                  <span
                    id="vr-360-fullwidth"
                    class="vr-360-btn"
                    @click="setFullWidthImages"
                  >
                    <span class="hi-expand"></span>
                  </span>

                  <span
                    id="close-vr-360"
                    class="vr-360-btn"
                    @click="onCloseImages360"
                  >
                    <span class="hi-close"></span>
                  </span>
                </div>

                <slick
                  ref="previewSlider"
                  :options="slickOptions"
                  v-if="property.images.length"
                  v-show="!images360IsActive"
                  class="single-property-slider"
                  @lazyLoaded="slickHandleLazyLoaded"
                >
                  <div
                    v-for="(image, key) in property.images"
                    :key="key"
                    class="slide-item"
                  >
                    <div class="slide-bg">
                      <img
                        loading="lazy"
                        :src="image.normalImage.path"
                        :alt="image.normalImage.key"
                      />
                    </div>
                  </div>
                </slick>
              </div>
            </div>
          </div>

          <div
            class="single-content"
            id="single-content"
            v-show="!images360IsActive"
          >
            <!-- Loading Placeholder -->
            <div v-if="loading" class="preview-data-area">
              <div class="progress-indecator data-item">
                <content-placeholders>
                  <content-placeholders-heading :img="true" />
                </content-placeholders>
              </div>
              <div class="data-item" v-for="i in 6" :key="i">
                <content-placeholders>
                  <content-placeholders-text :lines="3" />
                </content-placeholders>
              </div>
            </div>
            <div v-else class="single-content-inner">
              <div class="preview-data-area row">
                <div class="col-sm-6 p-0">
                  <!-- For Mobild Only: hidden on large and dublicated below on sidebar -->
                  <div class="item-main-data mobile-main-data-card">
                    <div class="main-data">
                      <span v-if="property.type">{{
                        property.type.nameAr
                      }}</span>
                      <span
                        v-if="
                          property.purpose && hasPurpose(property.type.nameEn)
                        "
                      >
                        {{ property.purpose.nameAr }}
                      </span>
                      <span v-if="property.status">{{
                        property.status.nameAr
                      }}</span>
                    </div>
                    <div class="price-currency" v-if="property.price">
                      <span class="price"
                        >{{ numberWithCommas(property.price) }}
                      </span>
                      <span class="unit-item">ريال</span>
                      <span
                        class="unit-item"
                        v-if="property.status.nameAr === 'للإيجار'"
                      >
                        /
                        {{
                          property.payType != null
                            ? payTypeOptions[property.payType].text
                            : ''
                        }}
                      </span>
                    </div>
                  </div>

                  <div class="data-item">
                    <span class="data-icon hi-map-pin"></span>
                    <span class="data-value">
                      {{
                        property.propertyAddress
                          ? property.propertyAddress.address
                          : ''
                      }}
                    </span>
                  </div>

                  <div class="data-item">
                    <span class="data-icon hi-info-o"></span>
                    <span class="data-label">صفة المعلن:</span>
                    <span class="data-value"> {{ advertiserStatus }} </span>
                  </div>

                  <div v-if="licenseNumber" class="data-item">
                    <span class="data-icon hi-hash"></span>
                    <span class="data-label">رقم المعلن:</span>
                    <span class="data-value"> {{ licenseNumber }} </span>
                  </div>

                  <div
                    v-if="property.ad && property.ad.number"
                    class="data-item"
                  >
                    <span class="data-icon hi-hash"></span>
                    <span class="data-label">رقم الإعلان:</span>
                    <span class="data-value">
                      {{ property.ad.number }}
                    </span>
                  </div>

                  <div class="data-item" v-if="property.populationType">
                    <span class="data-icon hi-chair"></span>
                    <span class="data-label">نوع السكن:</span>
                    <span class="data-value">
                      {{ property.populationType.nameAr }}
                    </span>
                  </div>

                  <div class="data-item" v-if="property['space']">
                    <span class="data-icon hi-area"></span>
                    <span class="data-label">مساحة العقار:</span>
                    <span class="data-value">
                      {{ property['space'] }}
                      <span class="unit-item">متر</span>
                    </span>
                  </div>

                  <div class="data-item" v-if="property.numberOfUnit">
                    <span class="data-icon hi-city"></span>
                    <span class="data-label">عدد الوحدات:</span>
                    <span class="data-value">{{ property.numberOfUnit }}</span>
                  </div>

                  <div class="data-item" v-if="property.floor">
                    <span class="data-icon hi-layers"></span>
                    <span class="data-label">{{ floorText }}:</span>
                    <span class="data-value">{{ property.floor }}</span>
                  </div>

                  <div class="data-item" v-if="property.age">
                    <span class="data-icon hi-calender"></span>
                    <span class="data-label">عمر العقار:</span>
                    <span class="data-value">{{ property.age }}</span>
                  </div>

                  <div class="data-item" v-if="property.numberOfRooms">
                    <span class="data-icon hi-bed"></span>
                    <span class="data-label">عدد الغرف {{ unitsText }}:</span>
                    <span class="data-value">{{ property.numberOfRooms }}</span>
                  </div>

                  <div class="data-item" v-if="property.numberOfKitchenUnit">
                    <span class="data-icon hi-platter"></span>
                    <span class="data-label">عدد المطابخ {{ unitsText }}:</span>
                    <span class="data-value">
                      {{ property.numberOfKitchenUnit }}
                    </span>
                  </div>

                  <div class="data-item" v-if="property.numberOfBathRoom">
                    <span class="data-icon hi-bathroom"></span>
                    <span class="data-label">
                      عدد دورات المياة {{ unitsText }}:
                    </span>
                    <span class="data-value">
                      {{ property.numberOfBathRoom }}
                    </span>
                  </div>

                  <div class="data-item" v-if="property.numberOfLivingRoom">
                    <span class="data-icon hi-hall"></span>
                    <span class="data-label">عدد الصالات {{ unitsText }}:</span>
                    <span class="data-value">
                      {{ property.numberOfLivingRoom }}
                    </span>
                  </div>

                  <div class="data-item" v-if="property.streetWidth">
                    <span class="data-icon hi-road"></span>
                    <span class="data-label">عرض الشارع:</span>
                    <span class="data-value">
                      {{ property.streetWidth }}
                      <span class="unit-item">متر</span>
                    </span>
                  </div>

                  <div
                    class="data-item flex-wrap"
                    v-if="
                      property.selectedSides &&
                      property.selectedSides.length > 0
                    "
                  >
                    <span class="data-icon hi-road"></span>
                    <span class="data-label"
                      >الأرض مطلة على شوارع من الناحية:</span
                    >
                    <div class="fullwidth-value">
                      <span
                        class="data-value feature-item ml-3"
                        v-for="item in property['selectedSides']"
                        :key="item"
                      >
                        <span class="icon hi-check"></span>
                        {{ item }}
                      </span>
                    </div>
                  </div>

                  <div
                    class="data-item flex-wrap"
                    v-if="
                      property['features'] && property['features'].length > 0
                    "
                  >
                    <span class="data-icon hi-home2"></span>
                    <span class="data-label">مميزات العقار:</span>
                    <div class="fullwidth-value">
                      <span
                        class="data-value feature-item ml-3"
                        v-for="(item, key) in property['features']"
                        :key="key"
                      >
                        <span class="icon hi-check"></span>
                        {{ item.nameAr }}
                      </span>
                    </div>
                  </div>

                  <div class="data-item flex-wrap" v-if="property.notes">
                    <span class="data-icon hi-info-o"></span>
                    <span class="data-label">عن العقار:</span>

                    <div
                      class="show-more data-value"
                      :class="{ visible: isVisible }"
                    >
                      <p>{{ property.notes }}</p>
                      <a
                        class="show-more-button"
                        href="#"
                        @click.prevent="isVisible = !isVisible"
                      >
                        عرض المزيد
                        <i class="hi-angle-down"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <!--   -->
                <div class="col-sm-6 sidebar-wrapper">
                  <div class="sidebar">
                    <div class="item-main-data hidden-sm-down">
                      <div class="main-data">
                        <span>{{ property.type.nameAr }}</span>
                        <span
                          v-if="
                            property.purpose && hasPurpose(property.type.nameEn)
                          "
                        >
                          {{ property.purpose.nameAr }}
                        </span>

                        <span v-if="property.status">
                          {{ property.status.nameAr }}
                        </span>
                      </div>
                      <div class="price-currency" v-if="property.price">
                        <span class="price">
                          {{ numberWithCommas(property.price) }}
                        </span>
                        <span class="unit-item">ريال</span>
                        <span
                          class="unit-item"
                          v-if="
                            property.status &&
                            property.status.nameEn === 'rent' &&
                            (property.payType === 0 ||
                              property.payType === 1 ||
                              property.payType === 2)
                          "
                        >
                          /
                          {{
                            property.payType != null
                              ? payTypeOptions[property.payType].text
                              : ''
                          }}
                        </span>
                      </div>
                      <div class="price-currency" v-else>
                        <span class="unit-item">علي السوم</span>
                      </div>
                    </div>
                    <!-- Agent Data -->
                    <div class="agent-widget" v-if="owner">
                      <div class="agent-details">
                        <h4>
                          <a class="ownerRoute" @click="ownerRoute">
                            {{ owner.company || owner.name }}
                          </a>
                        </h4>
                        <span>{{
                          getConfigNameByCode('userTypes', 'Ar', owner.type)
                        }}</span>
                      </div>

                      <div
                        class="agent-contact"
                        v-if="Object.keys(owner).length !== 0"
                      >
                        <h4
                          style="direction: ltr"
                          class="my-2 font-weight-bold"
                        >
                          +{{ owner.phoneNumber }}
                        </h4>
                        <div
                          class="d-flex align-items-center justify-content-center"
                        >
                          <a
                            class="list-tags"
                            :href="
                              isLoggedIn
                                ? `tel:${owner.phoneNumber}`
                                : 'javascript:void(0)'
                            "
                            @click="
                              !isLoggedIn
                                ? logInToast(
                                    'لمهاتفه مالك العقار, يرجي تسجيل الدخول أولا '
                                  )
                                : ''
                            "
                          >
                            <i class="hi-phone-wave"></i>
                          </a>
                          <a
                            class="list-tags"
                            :target="isLoggedIn ? _blank : ''"
                            :href="
                              isLoggedIn
                                ? `https://api.whatsapp.com/send?phone=${owner.phoneNumber}&text=السلام عليكم ورحمه الله ${owner.name} ${this.propUrl}`
                                : 'javascript:void(0)'
                            "
                            @click="
                              !isLoggedIn
                                ? logInToast(
                                    'لارسال رساله عبر الواتساب , يرجي تسجيل الدخول أولا '
                                  )
                                : ''
                            "
                          >
                            <i class="hi-whatsapp"></i>
                          </a>
                          <a
                            v-if="!isOwner"
                            :href="
                              isLoggedIn
                                ? `/messaging?receiver=${encodeURIComponent(
                                    JSON.stringify(receiver)
                                  )}`
                                : 'javascript:void(0)'
                            "
                            class="list-tags"
                            @click="
                              !isLoggedIn
                                ? logInToast(
                                    'لارسال رساله , يرجي تسجيل الدخول أولا '
                                  )
                                : ''
                            "
                          >
                            <img
                              :src="messagesIcon"
                              class="messages-icon"
                              alt="messages"
                            />
                          </a>
                        </div>
                      </div>
                    </div>

                    <div
                      class="data-item progress-indecator-wrap"
                      v-if="isOwner"
                    >
                      <div class="progress-indecator">
                        <Progress
                          :radius="26"
                          :strokeWidth="3"
                          :value="property.completePercentage"
                          strokeColor="#3cab74"
                        ></Progress>
                        <div class="progress-info">
                          <h4>نسبة إكتمال تفاصيل العقار</h4>
                          <span>
                            كلما كانت نسبة الاكتمال اعلى، زادت فرص البيع او
                            التأجير
                          </span>
                        </div>
                      </div>
                    </div>
                    <rating-property
                      v-if="!isOwner"
                      :property="property"
                      :user="user ? user._id : false"
                      :isRated="isRated"
                    ></rating-property>
                  </div>
                </div>
              </div>

              <div class="notification notice" v-if="isOwner && hasImages360">
                <div>
                  <h4 class="m-0 mb-1">
                    يمكنك إضافة عرض ثلاثي الأبعاد لهذا العقار
                  </h4>
                  <p>
                    تساعد تقنية الصور ٣٦٠ الزائرين في استكشاف عقارك بطريقة أفضل
                  </p>
                </div>
              </div>

              <!-- Floorplans -->
              <div class="single-block" v-if="property.bluePrint.length > 0">
                <h4>المخططات</h4>
                <div class="style-1 fp-accordion">
                  <div class="accordion ui-accordion">
                    <div
                      v-for="(layout, index) in property.bluePrint"
                      :key="index"
                    >
                      <h3
                        v-b-toggle="'accordion-' + layout._id"
                        class="ui-accordion-header"
                        :class="
                          layout.visible ? 'ui-accordion-header-active' : null
                        "
                      >
                        <span v-if="layout.title">{{ layout.title }}</span>
                        <span v-else>مخطط العقار</span>
                        <i class="icon hi-angle-down"></i>
                      </h3>
                      <b-collapse
                        :id="'accordion-' + layout._id"
                        class="ui-accordion-content"
                        role="tabpanel"
                      >
                        <div>
                          <a class="floor-pic mfp-image">
                            <img :src="layout.image" alt="Floor Image" />
                          </a>
                          <p class="fp-desc p-4">{{ layout.desc }}</p>
                        </div>
                      </b-collapse>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Related Properties -->
              <related-properties
                v-if="images360IsActive === false && !isOwner"
              ></related-properties>

              <!-- FullWidth Images Modal -->
              <b-modal
                v-if="images.length"
                id="fullwidth-images-modal"
                ref="fullwidth-images-modal"
                title="مشاركة العقار"
                :shown="onModalShown"
                hide-footer
                hide-header
                size="xl"
                centered
              >
                <b-spinner
                  v-if="images && images.length"
                  label="Spinning"
                  variant="primary"
                ></b-spinner>

                <button
                  @click="hideModal('fullwidth-images-modal')"
                  type="button"
                  aria-label="Close"
                  class="close close-modal-btn"
                >
                  <span class="hi-close"></span>
                </button>

                <slick
                  id="fullwidthImagesSlider"
                  ref="fullwidthImagesSlider"
                  :options="slickOptions"
                  v-if="property.images.length"
                  :asNavFor="$refs.thumbsImagesSlider"
                  @beforeChange="syncSliderThumbs"
                  @lazyLoaded="slickHandleLazyLoaded"
                >
                  <div
                    v-for="(image, key) in property.images"
                    :key="key"
                    class="slide-item"
                  >
                    <div
                      class="slide-bg ppop"
                      :style="`background-image:url(${image.normalImage.path})`"
                    ></div>
                  </div>
                </slick>

                <slick
                  id="thumbsImagesSlider"
                  ref="thumbsImagesSlider"
                  :options="thumbsSlickOptions"
                  :asNavFor="$refs.fullwidthImagesSlider"
                  @beforeChange="syncSliderFull"
                  v-if="property.images.length"
                  @lazyLoaded="slickHandleLazyLoaded"
                >
                  <div
                    v-for="(image, key) in property.images"
                    :key="key"
                    class="slide-item"
                  >
                    <div
                      class="slide-bg"
                      :style="`background-image:url(${image.smallImage.path})`"
                    ></div>
                  </div>
                </slick>
              </b-modal>
            </div>
          </div>

          <!-- Generate Pdf -->
          <property-pdf v-show="false" :property="property"></property-pdf>
        </div>
      </div>
      <div id="map-toggle-nav" v-if="!images360IsActive">
        <!-- Current Locatino btn -->
        <button
          class="btn btn--white btn--shadow back-btn"
          id="gps-btn-2"
          @click="backBtn"
        >
          <span class="hi-arrow-right icon ml-2"></span> عودة
        </button>

        <button
          class="btn btn--white btn--shadow gps-btn-2"
          id="gps-btn-2"
          variant="light"
          @click="geolocate()"
        >
          <span class="hi-gps-fixed icon"></span>
        </button>

        <b-button
          @click="isMapView = !isMapView"
          :class="{
            'map-is-active': isMapView,
            'properties-is-active': !isMapView
          }"
          variant="primary"
          class="btn btn--white btn--shadow map-toggle-btn"
        >
          <span v-if="!isMapView">
            <span class="hi-map ml-2"></span>
            <span class="text">الخريطة</span>
          </span>
          <span v-else>
            <span class="hi-apartment ml-2"></span>
            <span class="text" v-if="isPropertyPage">تفاصيل العقار</span>
            <span class="text" v-else>قائمة العقارات</span>
          </span>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import Slick from 'vue-slick'
import { Circle2 } from 'vue-loading-spinner'
import store from '@/store'
import { gmapApi } from 'vue2-google-maps'
import GmapCustomMarker from '@/components/map/GmapCustomMarker.vue'
import { EventBus } from '@/services/EventBus'
import config from '@/config'
import Progress from 'easy-circular-progress'
import VueStar from 'vue-star'
import { realEstateService } from '@/services' //Modals
import ratingProperty from '@/components/property/ratingProperty.vue'
import PropertyPdf from '@/components/property/PropertyPdf.vue'
import RelatedProperties from '@/components/property/RelatedProperties.vue'
import SharePropertyModal from '@/components/modals/SharePropertyModal.vue'
import moment from 'moment-timezone'
import 'moment/locale/ar'
import Vr360 from 'vue-360vr'

//Style
import '@/styles/common/slick-slider.scss'
import '@/styles/property/common-in-addproperty-and-single.scss'
import '@/styles/property/map.scss'

function getBoundsZoomLevel(bounds, mapDim) {
  let WORLD_DIM = { height: 256, width: 256 }
  let ZOOM_MAX = 21

  function latRad(lat) {
    var sin = Math.sin((lat * Math.PI) / 180)
    var radX2 = Math.log((1 + sin) / (1 - sin)) / 2
    return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2
  }

  function zoom(mapPx, worldPx, fraction) {
    return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2)
  }

  var ne = bounds.getNorthEast()
  var sw = bounds.getSouthWest()

  var latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI

  var lngDiff = ne.lng() - sw.lng()
  var lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360

  var latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction)
  var lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction)

  return Math.min(latZoom, lngZoom, ZOOM_MAX)
}

export default {
  layout: 'map',
  name: 'property',
  props: ['closeImages360'],
  components: {
    Slick,
    Progress,
    Circle2,
    GmapCustomMarker,
    VueStar,
    ratingProperty,
    SharePropertyModal,
    RelatedProperties,
    Vr360,
    PropertyPdf
  },
  data() {
    return {
      map: null,
      firstLoad: true,
      isFirstTime: true,
      isFirstTilesLoad: true,
      owner: {},
      options: {
        scrollwheel: false,
        fullscreenControl: false,
        streetViewControl: false,
        minZoom: 9,
        mapTypeControlOptions: {
          style: 1,
          position: 12
        },
        styles: config.mapStyles
      },
      loading: false,
      currentZoom: null,
      fullMap: false,
      activeFilters: {
        pageSize: 6,
        //pageNumber: 1,
        // placeAddress: null,
        lat1: null,
        lat2: null,
        long1: null,
        long2: null
      },
      mapMarkers: [],
      infoWindow: {
        position: null,
        id: null,
        img: null,
        type: null,
        status: null,
        purpose: null,
        space: null,
        price: null,
        currency: null,
        numberOfRooms: null,
        numberOfBathRoom: null
      },
      infoWinOpen: false,
      infoWindowOptions: {
        disableAutoPan: true,
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      propId: null,
      currentPropertyId: null,
      fullWidthImages: false,
      images360IsActive: false,
      isMapView: false,
      property: {},
      slickOptions: { 
        slidesToShow: 1,
        rtl: true,
        dots: false,
        lazyLoad: 'ondemand',
        infinit: false
      },
      thumbsSlickOptions: {
        slidesToShow: 7,
        rtl: true,
        dots: false,
        arrows: false,
        centerMode: true,
        focusOnSelect: true,
        lazyLoad: 'ondemand',
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 5
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 4
            }
          },
          {
            breakpoint: 575,
            settings: {
              slidesToShow: 3
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2
            }
          }
        ]
      },
      likesCount: 0,
      requestService: [],
      isVisible: false,

      // check property meta
      isLiked: false,
      isRated: false,
      request1: false,
      request2: false,
      request3: false,
      hasImages360: false,
      currentImages360: 0,
      stikyKit: {
        options: {
          parent: '.sidebar-wrapper',
          offset_top: 170
        }
      },

      floorText: 'رقم الدور',
      unitsText: '',
      fontFile: require('@/styles/fonts/tma/TheMixArabic-Plain.ttf'),
      fontFileBold: require('@/styles/fonts/tma/TheMixArabic-Bold.ttf'),
      messagesIcon: require('../../assets/images/svgs/messages-icon.svg'),
      userData: JSON.parse(localStorage.getItem('tokenData'))
    }
  },
  async created() {
    this.setMapCenterFromUrl()
    this.setMapEventsFlag(false)

    // this.setPropertiesFound([]);

    EventBus.$on('placeSelected', (place) => {
      if (this.map && place.types) {
        // Viewport Bounderies
        var viewportBounds = this.map.getBounds(),
          zoom
        if (place.types.includes('country')) {
          // all the country
          zoom = 5
        } else if (place.types.includes('locality')) {
          // city
          zoom = 10
        } else if (place.types.includes('route')) {
          // street
          zoom = 14
        } else if (place.types.includes('point_of_interest')) {
          // spcial place
          zoom = 16
        } else if (
          place.types.includes('neighborhood') ||
          place.types.includes(
            'sublocality_level_1' || place.types.includes('sublocality')
          )
        ) {
          // spcial place
          zoom = 14
        } else {
          var mapConainer = document.getElementById('map-container')
          var w = mapConainer.offsetWidth
          var h = mapConainer.offsetHeight
          zoom = getBoundsZoomLevel(viewportBounds, { width: w, height: h })
        }
        this.map.setZoom(zoom)
      }
    })
    EventBus.$on('on-toggle-infoWindow', (property, id) => {
      this.toggleInfoWindow(property, id)
    })

    EventBus.$on('on-close-infoWindow', () => {
      this.closeInfoWinOpen()
    })
    await this.propertyInfo()
    await this.getLikesCount(this.$route.params.id)
  },

  watch: {
    closeImages360(val) {
      if (val == false) {
        this.images360IsActive = false
      }
    },
    $route: async function () {
      await this.propertyInfo()
    },
    '$route.params.id': async function (val) {
      this.likesCount = 0
      await this.getLikesCount(val)
      this.checkLike(val)
      if (!this.property.numberOfLikes > 0) {
        this.isLiked = false
      }
    }
  },

  async mounted() {
    this.setSpinnerLoading(false)
    this.$refs.mapRef.$mapPromise.then(() => {
      if (this.$route.name == 'properties')
        this.map = this.$refs.mapRef.$mapObject
    })

    let propId = this.$route.params.id
    if (this.isLoggedIn) {
      await this.checkLike(propId)
    }
    this.viewsCount(propId)

    // Add Prop to Visited Properties
    this.addtoVisitedProps(propId)

    // open iamges 360
    if (this.$route.params.images360 == 1) this.images360IsActive = true

    // set current marker
    this.$emit('set-current-marker', propId)
  },

  computed: {
    google: gmapApi,
    ...mapState('userState', ['spinnerLoading']),
    ...mapState('realEstateState', [
      'propertiesFound',
      'numberOfRealEstates',
      'pageSize',
      'mapEventsFlag',
      'mapCenter',
      'mapZoom',
      'urlQuery',
      'visitedProps'
    ]),
    ...mapState('accountState', [
      'status',
      'user',
      'favorites',
      'favoritesIds'
    ]),
    ...mapGetters('accountState', ['getConfigNameById', 'getConfigNameByCode']),
    peopertyName() {
      return this?.property?.propertyName
    },
    propUrl() {
      return config.BASE_URL + 'property/' + this.property._id + '/0'
    },
    showBackLink() {
      return this.$route.params.id !== 'default'
    },
    showMyPropertiesBtn() {
      return this.fullMap == true && this.$route.params.id === this.user._id
    },
    showMyFavoritesBtn() {
      return this.fullMap == true && this.$route.params.id == 'my-favorites'
    },

    isPropertyPage() {
      return this.$route.name == 'property'
    },
    numberOfLikes() {
      let likes = this.property.numberOfLikes
        ? this.property.numberOfLikes + this.likesCount
        : this.likesCount
      return likes
    },
    isLoggedIn() {
      return this.status.loggedIn
    },
    isOwner() {
      if (this.property && this.property.account) {
        return this.user && this.user._id === this.property.account.id
      }
      return false
    },
    payTypeOptions() {
      return config.payTypeOptions
    },
    selectedSides() {
      return config.selectedSides
    },
    images() {
      let images = this.property.images
      if (images.length > 1) {
        let index = this.property.mainImageIndex
        images.unshift(images.splice(index, 1)[0])
      }
      return images
    },
    imagesSmall() {
      let imagesSmall = this.property.imagesSmall
      if (imagesSmall?.length > 1) {
        let index = this.property.mainImageIndex
        imagesSmall.unshift(imagesSmall.splice(index, 1)[0])
      }
      return imagesSmall
    },
    receiver() {
      return {
        receiver:
          this.property.moderator ||
          this.property.owner ||
          this.realEstateOwner,
        subjectId: this.property._id
      }
    },
    isLand() {
      return this.property?.type?.nameEn === 'land'
    },
    isPropertyHasNoImages() {
      return this.images && this.images.length === 0
    },
    isSubLocationsExist() {
      return (
        this.checkIfSubLocationExists('city') ||
        this.checkIfSubLocationExists('district') ||
        this.checkIfSubLocationExists('province')
      )
    },
    realEstateOwner() {
      return {
        _id: this.owner?._id,
        email: this.owner?.email,
        name: this.owner?.name || this.owner?.company,
        phoneNumber: this.owner?.phoneNumber
      }
    },
    advertiserStatus() {
      return this.property.fromOwner ? 'مالك' : 'مفوض'
    },
    licenseNumber() {
      return this.owner?.licenseNumber
    }
  },

  methods: {
    ...mapActions('realEstateState', ['getSingleProperty', 'setUrlQuery']),
    ...mapMutations('realEstateState', [
      'addtoVisitedProps',
      'setPropertiesFound',
      'setPropertiesTitle',
      'setNumberOfRealEstates',
      'setMapCenter',
      'setMapEventsFlag',
      'setMapZoom'
    ]),
    ...mapMutations('userState', ['setSpinnerLoading']),
    ...mapActions('accountState', ['addToFavorite', 'deleteFromFavorite']),
    ...mapMutations('accountState', ['addToFavorites', 'deleteFromFavorites']),
    logInToast(text) {
      this.$toasted.show(text, {
        duration: 10000,
        type: 'error',
        icon: 'hi-login'
      })
    },
    beforeLeave() {
      this.loading = true
    },
    afterEnter() {
      this.loading = false
    },
    pdfDownload() {
      this.$children[2].downloadPdf()
    },
    ownerRoute() {
      const type =
        this.property.account.userType === 'office' ||
        this.property.account.userType === 'company'
      const name = this.property.account?.name || this.property.owner?.name

      type
        ? this.$router.push({
            name: 'agent-properties',
            params: { id: this.property.account.id },
            query: { name }
          })
        : this.$router.push({
            name: 'properties',
            params: { id: this.property.account.id },
            query: { name }
          })
    },
    toggleInfoWindow: function (property, id) {
      let position = {
        lat: property.location.coordinates[1],
        lng: property.location.coordinates[0]
      }
      this.infoWindow.position = position
      this.infoWindow.id = property._id
      this.infoWindow.type = property.type.nameAr
      this.infoWindow.status = property.status ? property.status.nameAr : ''
      this.infoWindow.space = property.space
      this.infoWindow.address = property.propertyAddress.address
      this.infoWindow.img = property.images
        ? property.images[0].smallImage.path
        : ''
      this.infoWindow.price = property.price
      this.infoWindow.currency = 'ريال'
      this.infoWindow.numberOfRooms = property.numberOfRooms
      this.infoWindow.numberOfBathRoom = property.numberOfBathRoom

      let markerPosition = { position }
      let marker = new window.google.maps.Marker(markerPosition)

      this.infoWindowOptions.pixelOffset = this.getInfowindowOffset(marker)

      //check if its the same marker that was selected if yes toggle
      if (this.propId == id) {
        this.infoWinOpen = !this.infoWinOpen
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true
        this.propId = id
      }
    },
    async getLikesCount(realestate) {
      const result = await realEstateService.likesCount(realestate)
      this.$set(this.property, 'numberOfLikes', result.data.likes)
    },

    closeInfoWinOpen() {
      this.infoWinOpen = false
    },

    getInfowindowOffset: function (marker) {
      if (this.map) {
        var center = this.getPixelFromLatLng(this.map.getCenter()),
          point = this.getPixelFromLatLng(marker.getPosition()),
          quadrant = '',
          offset
        quadrant += point.y > center.y ? 'b' : 't'
        quadrant += point.x < center.x ? 'l' : 'r'
        if (quadrant == 'tr') {
          offset = new window.google.maps.Size(-140, 245)
        } else if (quadrant == 'tl') {
          offset = new window.google.maps.Size(140, 245)
        } else if (quadrant == 'br') {
          offset = new window.google.maps.Size(-140, -45)
        } else if (quadrant == 'bl') {
          offset = new window.google.maps.Size(140, -45)
        }

        return offset
      }
    },
    getPixelFromLatLng: function (latLng) {
      var projection = this.map.getProjection()
      var point = projection.fromLatLngToPoint(latLng)
      return point
    },

    onDraged() {
      if (
        this.$route.name == 'properties' &&
        this.$route.params.id == 'default'
      ) {
        this.setSpinnerLoading(true) // Begin Loading
        var bounds = this.map.getBounds()
        this.currentZoom = this.map.getZoom()

        const ne = bounds.getNorthEast()
        const sw = bounds.getSouthWest()

        // 1- Set parmas for generate query
        this.setMapCenterFromMap() // to set lat, lng in url

        // this.setLatLongFromMapOrUrl(ne, sw);

        this.activeFilters.lat1 = sw.lat()
        this.activeFilters.lat2 = ne.lat()
        this.activeFilters.long1 = sw.lng()
        this.activeFilters.long2 = ne.lng()

        // Set lat lng from map center
        let { lat, lng } = this.mapCenter // From state
        this.activeFilters.lat = lat
        this.activeFilters.lng = lng

        if (!this.firstLoad) {
          this.activeFilters.pageNumber = 1
          EventBus.$emit('onDragMap', '')
        }
        this.firstLoad = false

        this.activeFilters.pageSize = this.pageSize // get pageSize From state

        // 2- Generate query & Update url
        realEstateService.generateQuery(this.activeFilters).then((query) => {
          // 3- Get results
          this.getRealEstate(query)
          this.setUrlQuery(query) // save query to use in in back btn.
        })

        // 4- Set properties title depend on lat,lng form url
        // let{lat, lng}= realEstateService.getCurrentUrlParamsObj();
        this.convertLatlngToAddress({ lat: Number(lat), lng: Number(lng) })
      }
    },

    onTilesLoaded() {
      if (!this.mapEventsFlag) {
        this.onDraged()
        this.setMapEventsFlag(true)
      }
    },

    onZoomChanged(zoom) {
      if (this.mapEventsFlag) {
        this.currentZoom = zoom
        this.onDraged()
      }
    },

    convertLatlngToAddress(location) {
      // Convert the latlang to formatted address
      if (this.map && location) {
        var geocoder = new window.google.maps.Geocoder()
        geocoder.geocode({ latLng: location }, (results) => {
          let placeAddress = results[0].formatted_address
          store.commit('realEstateState/setPropertiesTitle', placeAddress)
        })
      }
    },

    backBtn() {
      // debugger;
      this.$router.go(-1)

      // fix the backBtn issue, if you back to search results page.
      // Still want more clear and maintanable fix :(
      setTimeout(() => {
        if (this.$route.params.id == 'default') {
          this.onDraged()
        }
      }, 100)
    },

    async propertyInfo() {
      this.loading = true
      //this.getSingleProperty(this.$route.params.id)
      await realEstateService
        .getSingleProperty(this.$route.params.id)
        .then(async (response) => {
          if (response.data.realEstate) {
            this.property = response.data.realEstate
            const result = await realEstateService.getOwnerDetails(
              response.data.realEstate.account.id
            )
            this.owner = result.data.user

            this.loading = false
            // center map
            var mapCenter = {
              lat: this.property.location.coordinates[1],
              lng: this.property.location.coordinates[0]
            }
            store.commit('realEstateState/setMapCenter', mapCenter)
            // store.commit('realEstateState/setMapZoom', 17);

            // Set Page Title
            let type = this.property.type.nameAr
            let status = this.property.status.nameAr
            let payType =
              this.property.status.nameEn == 'rent' && this.property.payType
                ? '/' + this.payTypeOptions[this.property.payType].text
                : ''
            document.title = `${type} ${status} - ${
              this.property.price
                ? this.numberWithCommas(this.property.price) + 'ريال' + payType
                : 'علي السوم'
            }`
            // https://medium.com/js-dojo/how-to-add-dynamic-meta-tags-to-your-vue-js-app-for-google-seo-b827e8c84ee9

            // SEO og:img Meta Image
            if (this.images[0]) {
              const metaTags = {
                'og:image': this.images[0].normalImage?.path,
                'og:url': `${location.origin}/property/${this.property._id}/0`,
                'og:image:type': 'image/jpeg'
              }
              this.setMetaTags(metaTags)
            }
            // SEO og:discription Meta Description
            if (this.property.notes) {
              const notesMetaTag = { 'og:description': this.property.notes }
              this.setMetaTags(notesMetaTag)
            }

            // Default Texts
            if (
              this.property.type.nameEn === 'building' ||
              this.property.type.nameEn === 'Tower' ||
              this.property.type.nameEn === 'Building'
            ) {
              this.floorText = 'عدد الأدوار'
              this.unitsText = 'في كل وحدة'
            } else if (this.property.type.nameEn === 'villa') {
              this.floorText = 'عدد الأدوار'
            } else if (this.property.type.nameEn === 'compound') {
              this.floorText = 'عدد الأدوار في كل وحدة'
              this.unitsText = 'في كل وحدة'
            }
          } else {
            //Property not found
            this.$router.push({ name: '404' })
          }
        })
        .catch(() => {
          this.loading = false
          return this.$router.push({ name: 'property-404' })
          // this.$toasted.show(error, { duration: 7000, type: 'error', icon: 'hi-close' });
        })
    },
    moment: function (date) {
      return moment(date)
    },

    slickHandleLazyLoaded(event, slick, image, imageSource) {
      image.attr('src', '')
      image.parent().css('background-image', 'url("' + imageSource + '")')
    },

    // Like Methods --------------------------------
    checkLike(propertyId) {
      realEstateService.checkLike(propertyId, this.user._id).then(
        (response) => {
          if (response.data) {
            this.isRated = response.data.rating > 0
            console.log('is rated', this.isRated)
            this.isLiked = response.data.likes > 0
            this.$refs.likeProperty.active = true
          }

          // this.request1 = response.data.request1;
          // this.request2 = response.data.request1;
          // this.request3 = response.data.request1;
        },
        (error) => {
          console.log(error)
        }
      )
    },
    addLike(propertyId, state) {
      // state = 1 > like, state = 2 > dislike
      realEstateService.addLike(propertyId, this.user._id).then(
        () => {
          if (state == 1) {
            this.isLiked = true
            this.likesCount++
            this.$toasted.show('تم تسجيل إعجابك!', {
              duration: 5000,
              type: 'success',
              icon: 'hi-check'
            })
          } else if (state == 2) {
            this.isLiked = false
            this.likesCount--
            this.$toasted.show('تم حذف إعجابك!', {
              duration: 3000,
              icon: 'hi-check'
            })
          }
        },
        (error) => {
          this.$toasted.show(error, {
            duration: 3000,
            type: 'error',
            icon: 'hi-close'
          })
        }
      )
    },
    toggleLike(propertyId) {
      if (!this.isLoggedIn) {
        this.$toasted.show('لابد من تسجيل الدخول أولاً', {
          duration: 2000,
          icon: 'hi-login'
        })
      } else if (this.isLiked) {
        this.addLike(propertyId, 2)
      } else {
        this.addLike(propertyId, 1)
      }
    },

    // Views Count :: add +1 on server
    viewsCount(propertyId) {
      realEstateService.viewsCount(propertyId)
    },

    // Favorites Methods ----------------------------
    isInFavorite(realestate) {
      // find favorite by user and realestate
      const favorite = this.favorites.find((fav) => {
        return fav.realestate._id === realestate
      })
      if (favorite && this.favoritesIds && this.favoritesIds.length) {
        return this.favoritesIds.includes(realestate)
      }
    },
    toggleFavorite(property) {
      let { _id: id } = property
      if (this.isLoggedIn) {
        this.isInFavorite(id, this.user._id)
          ? this.onDeleteFromFavorite(id, this.user._id)
          : this.onAddToFavorite(property)
      } else {
        this.$toasted.show('لابد من تسجيل الدخول أولاً', {
          duration: 2000,
          icon: 'hi-check'
        })
      }
    },
    async onAddToFavorite(property) {
      try {
        const response = await this.addToFavorite({
          realestate: property._id,
          user: this.user._id
        })
        if (response.data.favorite) {
          this.addToFavorites(response.data.favorite)
          this.$toasted.show('تم إضافة العقار لمفضلتك!', {
            duration: 2000,
            type: 'success',
            icon: 'hi-check'
          })
        }
      } catch (error) {
        this.$toasted.show(error, {
          duration: 2000,
          type: 'error',
          icon: 'hi-close'
        })
      }
    },
    async onDeleteFromFavorite(realestate, user) {
      try {
        await this.deleteFromFavorite({ realestate, user })
        this.$toasted.show('تم حذف العقار من المفضلة', {
          duration: 2000,
          icon: 'hi-check'
        })
        this.deleteFromFavorites({ realestate, user })
        if (this.isInfavoritesView && this.usedIn == 'properties')
          this.$emit('on-get-favorties')
      } catch (error) {
        this.$toasted.show(error, {
          duration: 2000,
          type: 'error',
          icon: 'hi-close'
        })
      }
    },
    onRequestServiceSubmit() {
      let data = {
        _id: this.property._id,
        request: 1
      }
      realEstateService.addRequest(data).then(() => {
        this.$toasted.show(
          'شكراً لك، تم إرسال طلبك، وسيتم التواصل معك في أقرب وقت ممكن',
          {
            duration: 5000,
            type: 'success',
            icon: 'hi-check'
          }
        )
      })
    },
    numberWithCommas(x) {
      return realEstateService.numberWithCommas(x)
    },

    hasPurpose(type) {
      return realEstateService.hasPurpose(type)
    },

    openImages360() {
      this.images360IsActive = true
      this.$emit('open-images-360')
    },

    onCloseImages360() {
      this.images360IsActive = false
      this.$emit('set-fullwidth-images', 'close')
    },

    setFullWidthImages() {
      this.$emit('set-fullwidth-images', 'toggle')
    },

    hideModal(ref) {
      this.$refs[ref].hide()
    },

    onModalShown() {
      alert('asdfasdfasdf')

      this.$nextTick(() => {
        this.$refs['fullwidthImagesSlider'].reSlick()
        this.$refs['thumbsImagesSlider'].reSlick()
      })
    },

    syncSliderThumbs(vent, slick, currentSlide, nextSlide) {
      this.$refs.thumbsImagesSlider.goTo(nextSlide)
    },

    syncSliderFull(vent, slick, currentSlide, nextSlide) {
      this.$refs.fullwidthImagesSlider.goTo(nextSlide)
    },

    setMapCenterFromMap() {
      // Set map center in state
      let lat = this.map.center.lat()
      let lng = this.map.center.lng()
      this.setMapCenter({ lat, lng })
    },

    setMapCenterFromUrl() {
      let { lat, lng } = realEstateService.getCurrentUrlParamsObj()
      if (lat && lng) {
        let center = { lat: Number(lat), lng: Number(lng) }
        this.setMapCenter(center)
      }
    },

    geolocate: function () {
      navigator.geolocation.getCurrentPosition((position) => {
        let center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }

        this.setMapCenter(center)
      })
    },

    setCurrentMarker(id) {
      this.currentPropertyId = id
    },

    onOpenimages360() {
      this.images360IsActive = true
    },

    numberFormatter(num, digits) {
      return realEstateService.numberFormatter(num, digits)
    },

    isInVisitedProps(id) {
      return this.visitedProps.includes(id)
    },
    checkIfSubLocationExists(subLocation) {
      return this.property?.propertyAddress?.[subLocation]
    },
    setMetaTags(metaTags) {
      Object.keys(metaTags).forEach((metaProperty) => {
        document.head.querySelector(
          `meta[property='${metaProperty}']`
        ).content = metaTags[metaProperty]
      })
    }
  },
  beforeRouteLeave(to, from, next) {
    to.name !== 'properties' && this.setMapZoom(12)
    next()
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/property/single-property.scss';
</style>
